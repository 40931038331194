// TODO: It's a temporary solution. Ideally we can have an endpoint with a real actual number of projects.
// This thing relies on statistics we have.

export const START_TIME_FOR_COUNTING_PROJECTS = 1551061829; // Monday, 25 February 2019, 2:30:29. Approximately calculated date.
export const INCREMENT_FOR_AMOUNT_OF_PROJECTS = 2;
export const SECONDS_BETWEEN_INCREMENTATION = 60 * 60;

export const generateCurrentAmountOfProjects = () => {
  const now = Date.now() / 1000;
  const difference = Math.floor(
    (now - START_TIME_FOR_COUNTING_PROJECTS) / SECONDS_BETWEEN_INCREMENTATION
  );
  const count = INCREMENT_FOR_AMOUNT_OF_PROJECTS * difference;

  return count;
};
