import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '@konsus/hooks';

const INITIAL_NUMBER_POSITION = 7;

export const AnimatedFlipCounter: React.FC<{ digit: number }> = ({ digit }) => {
  const [digitLastShown, setDigitLastShown] = useLocalStorage({
    key: 'digitLastShown',
    initialValue: { digit: 0 }
  });

  const [currentNumber, setCurrentNumber] = useState(
    (digit - INITIAL_NUMBER_POSITION).toLocaleString('en-US')
  );

  useEffect(() => {
    const animateValue = (start: number, end: number, duration: number) => {
      let startTimestamp: number | null = null;

      const step = (timestamp: number) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }

        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const finalNumber = Math.floor(progress * (end - start) + start);

        setCurrentNumber((prevCurrentNumber) => {
          if (finalNumber > parseInt(prevCurrentNumber)) {
            return finalNumber.toLocaleString('en-US');
          }

          return prevCurrentNumber;
        });

        if (progress < 1) {
          window.requestAnimationFrame(step);
        } else {
          setDigitLastShown({ digit });
        }
      };

      window.requestAnimationFrame(step);
    };

    if (typeof window !== 'undefined') {
      if (digitLastShown && digitLastShown.digit > digit) {
        setCurrentNumber(digitLastShown.digit.toLocaleString('en-US'));
      } else {
        animateValue(digit - INITIAL_NUMBER_POSITION, digit, INITIAL_NUMBER_POSITION * 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digit]);

  return <>{currentNumber}</>;
};
