const SIX_COLUMNS_MAX_WIDTH = '1920px';
const FOUR_COLUMNS_MAX_WIDTH = '940px';
const THREE_COLUMNS_MAX_WIDTH = '690px';

export const gridColumnsTokens = { name: '--grid-col', val: 'var(--grid-col)' };

export function useLogoSaladGrid(columns: number) {
  const maxWidth =
    columns === 6
      ? SIX_COLUMNS_MAX_WIDTH
      : columns === 4
      ? FOUR_COLUMNS_MAX_WIDTH
      : columns === 3
      ? THREE_COLUMNS_MAX_WIDTH
      : 'auto';

  return maxWidth;
}

export function getNumberOfColumns(items: number) {
  switch (items) {
    case 6:
      return 3;
    case 4:
    case 7:
    case 8:
      return 4;
    case 12:
    case 11:
      return 6;
    default:
      return 5;
  }
}
