import React, { useEffect, useState } from 'react';
import { Box, cn } from '@superside/ui';
import { PageSection } from '../../components/PageSection';
import Superscript from '../../components/Superscript';
import { LogoSaladItem } from './LogoSaladItem';
import AnimatedLogoSalad from './AnimatedLogoSalad';
import { type LogoSaladProps } from './types';
import {
  useLogoSaladGrid,
  getNumberOfColumns,
  AnimatedFlipCounter,
  generateCurrentAmountOfProjects
} from './utils';

const MINIMUM_AMOUNT_OF_LOGOS = 4;

export const LogoSalad: React.FC<LogoSaladProps> = (props) => {
  const {
    items,
    superscriptText,
    defaultClientLogos,
    paddingFields,
    animated = false,
    isLight,
    rows = 1,
    speed = 0.5,
    variables,
    ...rest
  } = props;
  const { paddingTop, paddingBottom } = paddingFields || {};
  const [processedSuperscript, setProcessedSuperscript] = useState<string | JSX.Element>(
    superscriptText ?? ''
  );
  const logos = items?.length >= MINIMUM_AMOUNT_OF_LOGOS ? items : defaultClientLogos;
  const columns = getNumberOfColumns(logos?.length);
  const flexWidth = `0 0 calc(${100 / columns}% - 24px)`;
  const maxWidth = useLogoSaladGrid(columns);

  useEffect(() => {
    if (superscriptText && /{[a-z]*}/.test(superscriptText) && variables) {
      const superscriptArrayWithVariables = (
        <div>
          {superscriptText
            .split(/[{,}]/)
            .filter(Boolean)
            .map((word, idx) => (
              <span key={idx}>
                {word === 'projects' ? (
                  <AnimatedFlipCounter digit={generateCurrentAmountOfProjects()} />
                ) : (
                  word
                )}
              </span>
            ))}
        </div>
      );

      setProcessedSuperscript(superscriptArrayWithVariables);
    } else if (superscriptText) {
      setProcessedSuperscript(superscriptText);
    }
  }, [superscriptText, variables]);

  return (
    <PageSection {...rest} paddingTop={paddingTop} paddingBottom={paddingBottom} unlimitedMobile>
      <PageSection paddingBottom='none' paddingTop='none'>
        {superscriptText ? (
          <Superscript
            textAlign='center'
            content={processedSuperscript}
            margin={{ bottom: '0' }}
            className={cn('1024:mb-10 mb-8', isLight && 'text-dark')}
          />
        ) : null}
      </PageSection>
      {animated ? (
        <AnimatedLogoSalad logos={logos} rows={rows} speed={speed} />
      ) : (
        <Box
          className={`mx-auto my-0 flex flex-row flex-wrap items-center justify-center gap-4 768:max-w-[${maxWidth}] 1204:gap-6`}
        >
          {logos
            ? logos.map((item) => <LogoSaladItem key={item._key} {...item} flexWidth={flexWidth} />)
            : null}
        </Box>
      )}
    </PageSection>
  );
};

export default LogoSalad;
